<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>
        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="success"-->
<!--                effect="dark" style="margin-bottom: 20px">-->
<!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="info"-->
<!--                effect="dark" style="margin-bottom: 20px">-->
<!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="warning"-->
<!--                effect="dark" style="margin-bottom: 20px">-->
<!--            </el-alert>-->

            <el-alert
                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"
                type="error"
                effect="dark" style="margin-bottom: 20px" >
            </el-alert>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "Promotion",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[

        {'index':0,'url':'http://admin.jzybyy.com/static/zhongyao/1001163.png','id':'4915','search':''},   //4915	1001163	阿胶补血口服液
        {'index':1,'url':'http://admin.jzybyy.com/static/zhongyao/1000408.png','id':'4365','search':''},   //4365	1000408	黄芪颗粒
        {'index':2,'url':'http://admin.jzybyy.com/static/zhongyao/1005107.png','id':'7982','search':''},   //7982	1005107	复方阿胶浆
        {'index':3,'url':'http://admin.jzybyy.com/static/zhongyao/1008219.png','id':'9981','search':''},   //9981	1008219	人参药酒

        {'index':4,'url':'http://admin.jzybyy.com/static/zhongyao/1014437.png','id':'13259','search':''},     //13259	1014437	红参(礼盒)
        {'index':5,'url':'http://admin.jzybyy.com/static/zhongyao/1031522.png','id':'16104','search':''},      //16104	1031522	灵芝孢子(礼盒装)
        {'index':6,'url':'http://admin.jzybyy.com/static/zhongyao/1031516.png','id':'16098','search':''},      //16098	1031516	西洋参(礼盒装)
        {'index':7,'url':'http://admin.jzybyy.com/static/zhongyao/1015249.png','id':'13719','search':''},      //13719	1015249	西洋参

        {'index':8,'url':'http://admin.jzybyy.com/static/zhongyao/1014439.png','id':'13261','search':''},     // 13261	1014439	红参片(礼盒)
        {'index':9,'url':'http://admin.jzybyy.com/static/zhongyao/1015272.png','id':'13738','search':''},     //13738	1015272	西洋参(礼盒)
        {'index':10,'url':'http://admin.jzybyy.com/static/zhongyao/1000536.png','id':'4455','search':''},     //4455	1000536	西洋参
        {'index':11,'url':'http://admin.jzybyy.com/static/zhongyao/1000218.png','id':'4259','search':''},     //4259	1000218	西洋参(礼盒)

        {'index':12,'url':'http://admin.jzybyy.com/static/zhongyao/1016035.png','id':'14278','search':''},       //14278	1016035	西洋参
        {'index':13,'url':'http://admin.jzybyy.com/static/zhongyao/1032440.png','id':'16134','search':''},       //16134	1032440	冬虫夏草(礼盒装)
        {'index':13,'url':'http://admin.jzybyy.com/static/zhongyao/1014564.png','id':'13338','search':''},      //13338	1014564	冬虫夏草
        {'index':15,'url':'http://admin.jzybyy.com/static/zhongyao/1000169.png','id':'4233','search':''},      //4233	1000169	冬虫夏草(礼盒)


        // {'index':24,'url':'http://admin.jzybyy.com/static/new/song.png','id':'','search':''},
        // {'index':25,'url':'http://admin.jzybyy.com/static/new/1005970.png','id':'8538','search':''},
        // {'index':26,'url':'http://admin.jzybyy.com/static/new/1007640.png','id':'9633','search':''},
        // {'index':27,'url':'http://admin.jzybyy.com/static/new/1010791.png','id':'11434','search':''},
        // {'index':28,'url':'http://admin.jzybyy.com/static/new/1011842.png','id':'12023','search':''},
        // {'index':29,'url':'http://admin.jzybyy.com/static/new/1013792.png','id':'12950','search':''},
        // {'index':30,'url':'http://admin.jzybyy.com/static/new/1010794.png','id':'11436','search':''},
        // {'index':31,'url':'http://admin.jzybyy.com/static/new/1014942.png','id':'13529','search':''},
      ],
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
